import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '个人中心',
    component: () => import('../views/center/index.vue'),
    meta: {
        title: '我的直播间',
        needLogin: true,
    }
  },
  {
    path: '/auth',
    name: 'auth',
    hidden: true,
    redirect: '/auth/login',
    component: () => import('../views/auth/layout.vue'),
    children: [{
        path: '/auth/login',
        meta: {
            title: '账号登录？',
            needLogin: false,
        },
        component: () => import('../views/auth/login.vue'),
    }],
  },
  {
    path: '/live',
    name: 'live',
    component: () => import('../views/live/index.vue'),
    meta: {
        title: '直播',
        needLogin: true,
    },
  }
]

const router = new VueRouter({
    routes
})

export default router