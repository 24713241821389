import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import eventBus from '@/utils/eventBus.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import '@/permission'

// 引入自定义全局css
import '@/assets/css/variable.scss'
import '@/assets/css/global.scss'
import '@/assets/icons'
import { isMobile } from '@/utils/utils';
Vue.prototype.$isMobile = isMobile;
Vue.prototype.$eventBus = eventBus;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
